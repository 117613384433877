/* Generated automagically by FhirStarter - DO NOT EDIT */
const imagingStudyWorklistSchema = {
	accountNumber: '__string__',
	accountStatus: '__string__',
	age: '__unsignedInt__',
	allergies: ['__Coding__'],
	authorizationNumbers: '__string__',
	authorizationPeriod: '__string__',
	basedOn: ['__Reference__'],
	birthDate: '__dateTime__',
	appointmentCancellationReason: '__string__',
	cellPhone: '__string__',
	chargeStatus: '__string__',
	communicationStatusName: '__string__',
	conditions: ['__Coding__'],
	dateTimeAddendum: '__dateTime__',
	dateTimeAddendumUTC: '__dateTime__',
	dateTimeOrdered: '__dateTime__',
	dateTimeOrderedUTC: '__dateTime__',
	dateTimeRead: '__dateTime__',
	dateTimeReadUTC: '__dateTime__',
	dateTimeReceived: '__dateTime__',
	dateTimeReceivedUTC: '__dateTime__',
	dateTimeSigned: '__dateTime__',
	dateTimeSignedUTC: '__dateTime__',
	dateTimeTranscribed: '__dateTime__',
	dateTimeTranscribedUTC: '__dateTime__',
	dateTimeVerified: '__dateTime__',
	dateTimeVerifiedUTC: '__dateTime__',
	dateTimeScheduled: '__dateTime__',
	dateTimeScheduledUTC: '__dateTime__',
	appointmentDateTime: '__dateTime__',
	appointmentDateTimeUTC: '__dateTime__',
	department: '__string__',
	description: '__string__',
	encounter: '__Reference(Encounter)__',
	ethnicity: ['__Coding__'],
	examDuration: '__unsignedInt__',
	examRoom: '__string__',
	fillerOrderNumber: '__string__',
	financialClass: '__Coding__',
	gender: '__string__',
	HealthcareServiceName: '__string__',
	healthcareServiceName: '__string__',
	homePhone: '__string__',
	identifier: ['__Identifier__'],
	imagingOrganization: '__string__',
	imagingOrganizationId: '__string__',
	insuranceCopay: '__string__',
	insuranceExpiry: '__dateTime__',
	insurancePayer: '__Reference(Organization)__',
	insuranceStatus: '__string__',
	internalDiagnosticRequestID: '__string__',
	internalManagingOrganizationID: '__string__',
	internalMasterOrganizationID: '__string__',
	internalOrganizationId: '__string__',
	internalOrganizationValue: '__string__',
	isLinked: '__string__',
	languages: ['__Coding__'],
	lastUpdated: '__string__',
	managingOrganization: '__string__',
	modality: ['__Coding__'],
	modalityModifier: '__string__',
	numberOfInstances: '__unsignedInt__',
	numFrames: '__unsignedInt__',
	numImages: '__unsignedInt__',
	numReports: '__unsignedInt__',
	objectType: '__string__',
	orderDateTime: '__dateTime__',
	orderDateTimeUTC: '__dateTime__',
	orderStatus: '__string__',
	patientAddress: '__string__',
	patientAge: '__unsignedInt__',
	patientBalance: '__unsignedInt__',
	patientContactMethod: '__string__',
	patientEmail: '__string__',
	patientID: '__string__',
	patientLocation: '__string__',
	performingPhysician: '__string__',
	performingPhysicianId: '__string__',
	performingPhysicianNPI: '__string__',
	performingTechnologist: '__string__',
	placerOrderNumber: '__string__',
	procedureCode: ['__CodeableConcept__'],
	race: ['__Coding__'],
	readingFacility: '__string__',
	readingPhysician: '__string__',
	readingPhysicianId: '__string__',
	readingPhysicianNPI: '__string__',
	reasonCode: ['__CodeableConcept__'],
	referringFacility: '__string__',
	referringFacilityId: '__string__',
	referringPhysician: '__string__',
	referringPhysicianId: '__string__',
	referringPhysicianNPI: '__string__',
	requestedAppointmentDateTime: '__dateTime__',
	requestedAppointmentDateTimeUTC: '__dateTime__',
	smokingStatus: ['__Coding__'],
	specialArrangement: '__string__',
	ssn: '__string__',
	state: '__string__',
	studyDateTime: '__dateTime__',
	studyID: '__string__',
	studyUID: '__string__',
	subject: '__Reference__',
	timeAtStatus: '__string__',
	timeZone: '__string__',
	transcriptionist: '__string__',
	transcriptionistFacility: '__string__',
	uid: '__string__',
	visitClass: '__string__',
	visitNumber: '__string__',
	orderNotes: '__string__',
	started: '__dateTime__',
	eTag: '__string__',
	priorStatus: '__string__',
	eligibilityStatus: '__string__',
	createdDateTimeUTC: '__string__',
	primaryPriorAuthorizationNumber: '__string__',
	consultingPhysician: ['__string__'],
};
export default imagingStudyWorklistSchema;
