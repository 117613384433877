/* Generated automagically by FhirStarter - but please feel free to edit this file */
import React, { useContext } from 'react';

import moment from 'moment';

import CheckIcon from '@mui/icons-material/Check';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import PatientProfileIcon from '@worklist-2/ui/src/components/Worklist/WorklistGridPatientProfile/PatientProfileIcon';
import { SelectionContext } from '@worklist-2/core/src/context/SelectionContext';
import { StudyStatusCellProvider } from '@worklist-2/ui/src/components/Worklist/StudyStatusCell';
import { PatientProfileCell } from '@worklist-2/ui/src/components/Worklist/WorklistGrid/cells/PatientProfileCell';
import OverflowTooltip from '@worklist-2/ui/src/components/OverflowTooltip';
import CheckboxCell from '@worklist-2/ui/src/components/Worklist/WorklistGrid/cells/CheckboxCell';
import StatusIndicatorCell, {
	GetStatusIndicatorDisplay,
} from '@worklist-2/ui/src/components/Worklist/WorklistGrid/cells/StatusIndicatorCell';
import { InlineEditCell } from '@rs-ui/components/Worklist/WorklistGrid/cells/InlineEditCell';
import {
	convertArrayToString,
	getExtensionValueCoding,
	getExtensionValueString,
	getExtensionValueCodingArrayConcat,
	getExtensionValueCodingArray,
	getUserFullName,
	sortCompareNumber,
	calculatePatientAgeAtStudy,
	getWorklistParsedName,
	getExtensionValueReference,
} from './utils';
import { isValidDateMapping } from '@rs-ui/components/utils/dateUtils';
import { fhirExtensionUrls } from '../../extension';
import CheckboxSelectAll from '@rs-ui/components/Worklist/WorklistGrid/cells/CheckboxSelectAll';

/**
 * These are the options rendered in the "Status" filter. This filter is special because values are filtered based on two
 * imaging study set properties:
 * - "PriorStatus": indicates the pre-authorization status for the respective line.
 * - "EligibilityStatus": indicates the eligibility status (regarding payments) for the respective line.
 *
 * The R4 API has custom logic to apply filtering for each option based on the "id" property. The selected option is passed
 * to the API like this:
 *
 * statusindicator=eligibility-Eligible
 *
 * The example above will filter all records that have statuses that represent "Pending Eligibility".
 */
export const statusIndicatorFilterOptions = [
	{
		id: 'eligibility-Eligible',
		display: 'Eligible',
		icon: 'eligible',
	},
	{
		id: 'eligibility-NotEligible',
		display: 'Not Eligible',
		icon: 'eligibleDenied',
	},
	{
		id: 'eligibility-Pending',
		display: 'Pending Eligibility',
		icon: 'eligiblePending',
	},
	{
		id: 'prior-ApprovedAuth',
		display: 'Approved Authorization',
		icon: 'preAuthAllCleared',
	},
	{
		id: 'prior-DeniedAuth',
		display: 'Denied Authorization',
		icon: 'preAuthDenied',
	},
	{
		id: 'prior-PendingAuthorization',
		display: 'Pending Authorization',
		icon: 'preAuthPending',
	},
	{
		id: 'charge-Posted',
		display: 'Charge Posted',
		icon: 'chargePosted',
	},
	{
		id: 'charge-PostFailed',
		display: 'Charge Post Failed',
		icon: 'chargePostFailed',
	},
	{
		id: 'charge-PostPending',
		display: 'Charge Post Pending',
		icon: 'chargePostPending',
	},
];

const ImagingStudyWorklistMapping = {
	id: {
		label: '',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
			excludeFromSave: true,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => resource.id,
	},
	accessionNumber: {
		label: 'Accession #',
		options: { filter: true, sort: true },
		variableTypeToParseWhenSorting: 'number',
		filterType: 'text-search',
		searchParameter: 'accessionNumber',
		getDisplay: resource =>
			resource?.identifier?.find(elem => elem.type?.coding?.some(item => item.code === 'ACSN'))?.value,
		accessorFn: row => row?.identifier?.find(elem => elem.type?.coding?.some(item => item.code === 'ACSN'))?.value,
	},
	primaryPriorAuthorizationNumber: {
		label: 'Primary Prior Authorization #',
		options: { filter: true, sort: true },
		variableTypeToParseWhenSorting: 'number',
		filterType: 'text-search',
		searchParameter: 'primarypriorauthorizationnumber',
		accessorFn: row => row.primaryPriorAuthorizationNumber,
	},
	assigningAuthority: {
		label: 'Assigning Authority',
		options: { filter: false, sort: true },
		variableTypeToParseWhenSorting: 'text',
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource =>
			resource.extension?.find(
				ext =>
					ext.url == fhirExtensionUrls.organization.issuer ||
					ext.url == fhirExtensionUrls.organization.defaultIssuer
			)?.valueReference.display,
		accessorFn: row =>
			row.extension?.find(
				ext =>
					ext.url == fhirExtensionUrls.organization.issuer ||
					ext.url == fhirExtensionUrls.organization.defaultIssuer
			)?.valueReference.display,
	},
	accountNumber: {
		label: 'Account #',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'accountNumber',
		getDisplay: resource => resource.accountNumber,
	},
	accountStatus: {
		label: 'Account Status',
		options: { filter: false, sort: false },
		filterType: 'multi-select',
		searchParameter: 'accountStatus',
		searchValueSet: 'accountStatus',
		displayValue: 'code',
		capitalizeOptions: true,
		getDisplay: resource => resource.accountStatus,
	},
	gender: {
		label: 'Gender',
		options: { filter: true, sort: true },
		filterType: 'multi-select',
		searchParameter: 'gender',
		searchValueSet: 'sex',
		getDisplay: resource => resource.gender?.toUpperCase(),
	},
	availability: {
		label: 'Availability',
		options: { filter: false, sort: false },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => resource.availability,
	},
	authorizationPeriod: {
		label: 'Authorization Period',
		options: { filter: false, sort: false },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => resource.authorizationPeriod,
	},
	appointmentCancellationReason: {
		label: 'Appointment Cancellation Reason',
		options: { filter: false, sort: true },
		filterType: 'text-search',
		searchParameter: 'cancellationReason',
		getDisplay: resource => resource.cancellationReason,
	},
	cdsId: {
		label: 'CDS ID',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'cdsId',
		getDisplay: resource => getExtensionValueString(resource, fhirExtensionUrls.procedureRequest.cdsId),
		accessorFn: row => getExtensionValueString(row, fhirExtensionUrls.procedureRequest.cdsId),
	},
	sourceDeviceAETitle: {
		label: 'Source Device AE Title',
		options: { filter: false, sort: true },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => resource.sourceDeviceAETitle,
	},
	smokingStatus: {
		label: 'Smoking Status',
		options: { filter: false, sort: false },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => convertArrayToString(resource.smokingStatus, 'display'),
		accessorFn: row => convertArrayToString(row.smokingStatus, 'display'),
	},
	statusIndicator: {
		label: 'Indicators',
		options: {
			filter: true,
			sort: false,
			useCustomExportFn: true,
		},
		filterType: 'checkbox-multi-select',
		searchParameter: 'statusIndicator',
		customFilterOptions: statusIndicatorFilterOptions,
		accessorFn: (row, index, column) => {
			let statusIndicators = GetStatusIndicatorDisplay(column, row);
			return statusIndicators.indicators.map(indicator => indicator.name)?.join('|');
		},
		cell: props => <StatusIndicatorCell column={props.column} row={props.row.original} />,
	},
	languages: {
		label: 'Languages',
		options: { filter: true, sort: false },
		filterType: 'multi-select',
		searchParameter: 'language',
		searchValueSet: 'language',
		getDisplay: resource => convertArrayToString(resource.languages),
		accessorFn: row => convertArrayToString(row.languages),
	},
	modality: {
		label: 'Modality',
		options: { filter: true, sort: false },
		variableTypeToParseWhenSorting: 'text',
		filterType: 'multi-select',
		searchParameter: 'modalitycode',
		searchValueSet: 'modality',
		getDisplay: resource => convertArrayToString(resource.modality, 'code'),
		accessorFn: row => convertArrayToString(row.modality, 'code'),
	},
	race: {
		label: 'Race',
		options: { filter: true, sort: false },
		filterType: 'multi-select',
		searchParameter: 'race',
		searchValueSet: 'race',
		getDisplay: resource => convertArrayToString(resource.race),
		accessorFn: row => convertArrayToString(row.race),
	},
	ethnicity: {
		label: 'Ethnicity',
		options: { filter: true, sort: false },
		filterType: 'multi-select',
		searchParameter: 'ethnicity',
		searchValueSet: 'ethnicity',
		getDisplay: resource => {
			if (resource.ethnicity instanceof Array) {
				const filtered = resource.ethnicity.filter(elem => elem.code !== 'UNK');
				return convertArrayToString(filtered);
			}
			return resource.ethnicity?.display || resource.ethnicity;
		},
		accessorFn: row => {
			if (row.ethnicity instanceof Array) {
				const filtered = row.ethnicity.filter(elem => elem.code !== 'UNK');
				return convertArrayToString(filtered);
			}
			return row.ethnicity.display || row.ethnicity;
		},
	},
	patientEmail: {
		label: 'Patient Email',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'patientEmail',
		getDisplay: resource => resource.patientEmail,
	},
	cellPhone: {
		label: 'Patient Cell Phone',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'cellPhone',
		getDisplay: resource => resource.cellPhone,
	},
	customField1: {
		label: 'Custom Field 1',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'customField1',
		getDisplay: resource => getExtensionValueString(resource, fhirExtensionUrls.common.customField1),
	},
	customField2: {
		label: 'Custom Field 2',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'customField2',
		getDisplay: resource => getExtensionValueString(resource, fhirExtensionUrls.common.customField2),
	},
	dateTimeAddendum: {
		label: 'Date/Time Addendum',
		options: { filter: true, sort: true },
		filterType: 'date-range',
		searchParameter: 'datetimeaddendum',
		getDisplay: resource => {
			const value = resource.dateTimeAddendumUTC;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
				}
			}
		},
		accessorFn: row => {
			const value = row.dateTimeAddendumUTC;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
				}
			}
		},
		toolTip: true,
	},
	lastUpdated: {
		label: 'Date/Time Last Updated',
		options: { filter: true, sort: true },
		filterType: 'date-range',
		searchParameter: 'lastupdated',
		getDisplay: resource => {
			const value = resource.meta?.lastUpdated;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
				}
			}
		},
		accessorFn: row => {
			const value = row.meta?.lastUpdated;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
				}
			}
		},
		toolTip: true,
	},
	dateTimeOrdered: {
		// this is the date/time that the study transitions to the ORDERED study status
		label: 'Date/Time Ordered',
		options: { filter: true, sort: true },
		variableTypeToParseWhenSorting: 'date',
		filterType: 'date-range',
		searchParameter: 'datetimeordered',
		getDisplay: resource => {
			const value = resource.dateTimeOrderedUTC;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
				}
			}
		},
		toolTip: true,
	},
	dateTimeRead: {
		label: 'Date/Time Read',
		options: { filter: true, sort: true },
		filterType: 'date-range',
		searchParameter: 'datetimeread',
		getDisplay: resource => {
			const value = resource.dateTimeReadUTC;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
				}
			}
		},
		toolTip: true,
	},
	dateTimeReceived: {
		label: 'Date/Time Received',
		options: { filter: true, sort: true },
		variableTypeToParseWhenSorting: 'date',
		filterType: 'date-range',
		searchParameter: 'datetimereceived',
		getDisplay: resource => {
			const value = resource.dateTimeReceivedUTC;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
				}
			}
		},
		toolTip: true,
	},
	dateTimeSigned: {
		label: 'Date/Time Signed',
		options: { filter: true, sort: true },
		filterType: 'date-range',
		searchParameter: 'datetimesigned',
		getDisplay: resource => {
			const value = resource.dateTimeSignedUTC;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
				}
			}
		},
		toolTip: true,
	},
	dateTimeTranscribed: {
		label: 'Date/Time Transcribed',
		options: { filter: true, sort: true },
		filterType: 'date-range',
		searchParameter: 'datetimetranscribed',
		getDisplay: resource => {
			const value = resource.dateTimeTranscribedUTC;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
				}
			}
		},
		toolTip: true,
	},
	dateTimeVerified: {
		label: 'Date/Time Verified',
		options: { filter: true, sort: true },
		filterType: 'date-range',
		searchParameter: 'datetimeverified',
		getDisplay: resource => {
			const value = resource.dateTimeVerifiedUTC;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
				}
			}
		},
		toolTip: true,
	},
	dateTimeScheduled: {
		label: 'Status To Scheduled',
		options: { filter: true, sort: true },
		filterType: 'date-range',
		searchParameter: 'datetimescheduled',
		getDisplay: resource => {
			const value = resource.dateTimeScheduledUTC;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
				}
			}
		},
		toolTip: true,
	},
	appointmentDateTime: {
		label: 'Scheduled Date/Time',
		options: { filter: true, sort: true },
		variableTypeToParseWhenSorting: 'date',
		filterType: 'date-range',
		searchParameter: 'appointmentDateTime',
		getDisplay: resource => {
			const value = resource.appointmentDateTimeUTC;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
				}
			}
		},
		toolTip: true,
	},
	department: {
		label: 'Department',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'department',
		getDisplay: resource => resource.department,
	},
	eligibility: {
		label: 'Eligibility',
		options: { filter: false, sort: true },
		filterType: 'multi-select',
		searchParameter: 'eligibility',
		searchValueSet: 'ordereligibility',
		getDisplay: resource =>
			getExtensionValueCoding(resource, fhirExtensionUrls.procedureRequest.eligibility).display,
	},
	communicationStatusName: {
		label: 'Communication Status Name',
		options: { filter: false, sort: true },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => resource.communicationStatusName,
	},
	fillerOrderNumber: {
		label: 'Filler Order #',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'fillerOrderNumber',
		getDisplay: resource => resource.fillerOrderNumber,
	},
	homePhone: {
		label: 'Home Phone',
		options: { filter: false, sort: true },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => resource.homePhone,
	},
	patientAge: {
		label: 'Age at Study',
		options: { filter: false, sort: true, sortCompare: sortCompareNumber },
		filterType: 'none',
		type: 'number',
		searchParameter: '',
		getDisplay: resource => calculatePatientAgeAtStudy(resource),
	},
	numImages: {
		label: '# of Images',
		options: { filter: true, sort: true, sortCompare: sortCompareNumber },
		variableTypeToParseWhenSorting: 'number',
		filterType: 'text-search',
		type: 'number',
		searchParameter: 'numImages',
		getDisplay: resource => resource.numImages,
	},
	insuranceCopay: {
		label: 'Insurance Copay',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'insuranceCopay',
		getDisplay: resource => resource.insuranceCopay,
	},
	insuranceExpiry: {
		label: 'Insurance Expiry',
		options: { filter: false, sort: false },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => resource.insuranceExpiry,
	},
	insurancePayer: {
		label: 'Primary Insurance',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'payer',
		getDisplay: resource => resource.insurancePayer?.display,
	},
	insuranceStatus: {
		label: 'Insurance Status',
		options: { filter: false, sort: false },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => resource.insuranceStatus,
	},
	managingOrganization: {
		label: 'Managing Organization',
		options: { filter: true, sort: true },
		variableTypeToParseWhenSorting: 'text',
		filterType: 'suggest',
		searchParameter: 'internalManagingOrganizationID',
		suggestScope: {
			resource: 'organization',
			label: 'name',
			extraParam: {
				organizationtype: 'PROV',
			},
		},
		getDisplay: resource => resource.managingOrganization,
	},
	patientLocation: {
		label: 'Patient Location',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'location',
		getDisplay: resource => resource.patientLocation,
	},
	numFrames: {
		label: '# of Frames',
		options: { filter: true, sort: true, sortCompare: sortCompareNumber },
		variableTypeToParseWhenSorting: 'number',
		filterType: 'text-search',
		type: 'number',
		searchParameter: 'numFrames',
		getDisplay: resource => resource.numFrames,
	},
	numberOfInstances: {
		label: '# of Instances',
		options: {
			filter: false,
			sort: true,
			sortCompare: sortCompareNumber,
		},
		variableTypeToParseWhenSorting: 'number',
		filterType: 'none',
		type: 'number',
		searchParameter: 'numinstances',
		getDisplay: resource => resource.numberOfInstances,
	},
	numReports: {
		label: '# of Reports',
		options: { filter: false, sort: true, sortCompare: sortCompareNumber },
		filterType: 'none',
		type: 'number',
		searchParameter: 'numReports',
		getDisplay: resource => resource.numReports,
	},
	orderCustomField1: {
		label: 'Order Custom Field 1',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'orderCustomField1',
		getDisplay: resource =>
			getExtensionValueString(resource, fhirExtensionUrls.imagingStudyWorklist.orderCustomField1),
	},
	orderCustomField2: {
		label: 'Order Custom Field 2',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'orderCustomField2',
		getDisplay: resource =>
			getExtensionValueString(resource, fhirExtensionUrls.imagingStudyWorklist.orderCustomField2),
	},
	orderCustomMemo: {
		label: 'Order Custom Memo',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'orderCustomMemo',
		getDisplay: resource =>
			getExtensionValueString(resource, fhirExtensionUrls.imagingStudyWorklist.orderCustomMemo1),
	},
	orderDateTime: {
		label: 'Order Placement Date/Time',
		options: { filter: true, sort: true },
		filterType: 'date-range',
		searchParameter: 'orderdatetime',
		getDisplay: resource => {
			const value = resource.orderDateTimeUTC;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
				}
			}
		},
		toolTip: true,
	},
	orderStatus: {
		label: 'Order Status',
		options: { filter: true, sort: true },
		filterType: 'multi-select',
		searchParameter: 'orderstatus',
		searchValueSet: 'requeststatus',
		displayValue: 'code',
		getDisplay: resource => resource.orderStatus,
	},
	imagingOrganization: {
		label: 'Imaging Organization',
		options: { filter: false, sort: true },
		filterType: 'suggest',
		searchParameter: 'imagingOrganizationID',
		suggestScope: {
			resource: 'organization',
			label: 'name',
			extraParam: {
				organizationtype: 'PROV',
				isimaging: true,
			},
		},
		getDisplay: resource => resource.imagingOrganization,
	},
	financialClass: {
		label: 'Financial Class',
		options: { filter: false, sort: true },
		filterType: 'multi-select',
		searchParameter: 'financialClass',
		searchValueSet: 'financialClass',
		getDisplay: resource => resource.financialClass?.display,
	},
	healthcareService: {
		label: 'Healthcare Service',
		options: { filter: false, sort: true },
		filterType: 'multi-select',
		searchParameter: 'healthcareService',
		searchValueSet: 'healthcareService',
		accessorFn: row => row.healthcareServiceName || row.HealthcareServiceName,
	},
	orderOfAppropriateness: {
		label: 'Order Of Appropriateness',
		options: { filter: false, sort: true },
		filterType: 'multi-select',
		searchParameter: 'appropriateness',
		searchValueSet: 'appropriateness',
		getDisplay: resource =>
			getExtensionValueCoding(resource, fhirExtensionUrls.procedureRequest.cdsAppropriate).display,
	},
	pharmaceutical: {
		label: 'Pharmaceutical',
		options: { filter: false, sort: false },
		filterType: 'multi-select',
		searchParameter: 'pharmaceutical',
		searchValueSet: 'pharmaceutical',
		getDisplay: resource => getExtensionValueCodingArrayConcat(resource, fhirExtensionUrls.common.pharmaceutical),
	},
	patientBalance: {
		label: 'Patient Balance',
		options: { filter: false, sort: true },
		filterType: 'none',
		searchParameter: 'patientBalance',
		getDisplay: resource => resource.patientBalance,
	},
	patientID: {
		label: 'Patient ID',
		options: { filter: true, sort: true },
		variableTypeToParseWhenSorting: 'number',
		filterType: 'text-search',
		searchParameter: 'patientID',
		getDisplay: resource => resource?.patientID,
	},
	performingPhysician: {
		label: 'Performing Physician',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'performingPhysician',
		getDisplay: resource => resource.performingPhysician,
		cell: props => (
			<InlineEditCell
				size="small"
				userName={props.getValue()}
				internalStudyId={props.row.original.id}
				searchParams={{
					ispractitionerroleactive: true,
					managingorganization: props.row.original?.internalManagingOrganizationID,
					isperformingphysician: true,
				}}
				onUpdate={performingPhysicianName => {
					props.table.options.meta?.updateData(
						props.row.index,
						'performingPhysician',
						performingPhysicianName
					);
				}}
				cellLabel="performingPhysician"
			/>
		),
	},
	performingPhysicianNPI: {
		label: 'Performing Physician NPI',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'performingPhysicianNPI',
		getDisplay: resource => resource.performingPhysicianNPI,
	},
	performingTechnologist: {
		label: 'Performing Technologist',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: '',
		getDisplay: resource => resource.performingTechnologist,
		cell: props => (
			<InlineEditCell
				size="small"
				userName={props.getValue()}
				internalStudyId={props.row.original.id}
				searchParams={{
					ispractitionerroleactive: true,
					managingorganization: props.row.original?.internalManagingOrganizationID,
					isperformingtechnologist: true,
				}}
				onUpdate={performingTechnologistName => {
					props.table.options.meta?.updateData(
						props.row.index,
						'performingTechnologist',
						performingTechnologistName
					);
				}}
				cellLabel="performingTechnologist"
			/>
		),
	},
	placerOrderNumber: {
		label: 'Placer Order #',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'placerOrderNumber',
		getDisplay: resource => resource.placerOrderNumber,
	},
	procedureCode: {
		label: 'Procedure Code',
		options: { filter: false, sort: false },
		filterType: 'multi-select',
		searchParameter: 'procedureCode',
		displayValue: 'code',
		getDisplay: resource => {
			if (resource.procedureCode) {
				const procedureCodeDisplay = [];
				const procedurecodeLength =
					resource.procedureCode && resource.procedureCode[0] && resource.procedureCode[0].coding
						? resource.procedureCode[0].coding.length
						: 0;
				let item;

				for (let i = 0; i < procedurecodeLength; i++) {
					item = resource.procedureCode[0]?.coding[i];
					if (item && item.code) {
						procedureCodeDisplay.push(item.code);
					} else if (item && item.display) {
						procedureCodeDisplay.push(item.display);
					}
				}
				return convertArrayToString(procedureCodeDisplay);
			}

			return resource.procedureCode;
		},
	},
	readingFacility: {
		label: 'Reading Organization',
		options: { filter: false, sort: true },
		filterType: 'suggest',
		searchParameter: 'readingFacility',
		suggestScope: {
			resource: 'organization',
			label: 'name',
			extraParam: {
				organizationtype: 'PROV',
				isreading: true,
			},
		},
		getDisplay: resource => resource.readingFacility,
	},
	readingPhysician: {
		label: 'Reading Physician',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'readingPhysician',
		getDisplay: resource => resource.readingPhysician,
		cell: props => (
			<InlineEditCell
				size="small"
				userName={props.getValue()}
				internalStudyId={props.row.original.id}
				searchParams={{
					ispractitionerroleactive: true,
					managingorganization: props.row.original?.internalManagingOrganizationID,
					isreadingphysician: true,
				}}
				onUpdate={readingPhysicianName => {
					props.table.options.meta?.updateData(props.row.index, 'readingPhysician', readingPhysicianName);
				}}
				cellLabel="readingPhysician"
			/>
		),
	},
	readingPhysicianNPI: {
		label: 'Reading Physician NPI',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'readingPhysicianNPI',
		getDisplay: resource => resource.readingPhysicianNPI,
	},
	patientAddress: {
		label: 'Patient Address',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'address',
		getDisplay: resource => resource.patientAddress,
	},
	referringFacility: {
		label: 'Referring Organization',
		options: { filter: false, sort: true },
		filterType: 'suggest',
		searchParameter: 'referringFacilityID',
		suggestScope: {
			resource: 'organization',
			label: 'name',
			extraParam: {
				organizationtype: 'PROV',
				isreferring: true,
			},
		},
		getDisplay: resource => resource.referringFacility,
	},
	referringPhysician: {
		label: 'Referring Physician',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'referringPhysician',
		variableTypeToParseWhenSorting: 'text',
		getDisplay: resource => ({
			accessionNumber: resource.identifier?.find(elem => elem.type?.coding?.some(item => item.code === 'ACSN'))
				?.value,
			referringPhysician: getUserFullName(resource.referringPhysician),
		}),
		cell: props => (
			<InlineEditCell
				size="small"
				userName={props.getValue()}
				searchParams={{
					ispractitionerroleactive: true,
					managingorganization: props.row.original?.internalManagingOrganizationID,
					isreferringphysician: true,
					_summary: true,
					_elements: 'name',
					active: true,
				}}
				onUpdate={referringPhysicianName => {
					props.table.options.meta?.updateData(props.row.index, 'referringPhysician', referringPhysicianName);
				}}
				cellLabel="referringPhysician"
				internalOrderId={props.row.original?.basedOn?.[0]?.id}
				referringPhysicianId={props.row.original?.referringPhysicianId}
			/>
		),
		accessorFn: row => getUserFullName(row.referringPhysician),
	},
	referringPhysicianNPI: {
		label: 'Referring Physician NPI',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'referringPhysicianNPI',
		getDisplay: resource => resource.referringPhysicianNPI,
	},
	requestedAppointmentDateTime: {
		label: 'Requested Appointment Date/Time',
		options: { filter: true, sort: true },
		filterType: 'date-range',
		searchParameter: 'requestedappointmentdatetime',
		getDisplay: resource => {
			const value = resource.requestedAppointmentDateTimeUTC;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
				}
			}
		},
		toolTip: true,
	},
	birthDate: {
		label: 'Date of Birth',
		options: { filter: false, sort: true },
		filterType: 'date-time',
		searchParameter: 'birthdate:exact',
		getDisplay: resource => {
			const value = resource.birthDate;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return moment(resource.birthDate).format('MM/DD/YYYY');
				}
			}
		},
		toolTip: true,
	},
	birthSex: {
		label: 'Birth Sex',
		options: { filter: true, sort: true },
		variableTypeToParseWhenSorting: 'number',
		filterType: 'multi-select',
		searchParameter: 'sex',
		searchValueSet: 'sex',
		getDisplay: resource =>
			resource.extension?.find(elem => elem.url === fhirExtensionUrls.common.birthSex)?.valueCode,
	},
	ssn: {
		label: 'SSN',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'ssn',
		getDisplay: resource => resource.ssn,
	},
	state: {
		label: 'Patient State/Province',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'state',
		getDisplay: resource => resource.state,
	},
	studyCustomMemo: {
		label: 'Study Custom Memo',
		options: { filter: false, sort: true },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => resource.studyCustomMemo,
	},
	studyComment: {
		label: 'Study Comment',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'studyComment',
		getDisplay: resource =>
			resource.extension?.find(elem => elem.url === fhirExtensionUrls.imagingStudy.studyComments)?.valueString,
	},
	studyID: {
		label: 'Study ID',
		options: { filter: true, sort: true },
		variableTypeToParseWhenSorting: 'number',
		filterType: 'text-search',
		searchParameter: 'studyID',
		getDisplay: resource => resource.studyID,
	},
	studyUID: {
		label: 'Study UID',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'studyUID',
		getDisplay: resource =>
			resource.identifier
				? resource.identifier?.find(elem => elem.system === 'urn:dicom:uid')?.value.replace(/^(urn:oid:)/, '')
				: '',
	},
	seriesUID: {
		label: 'Series UID',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource =>
			getExtensionValueString(resource, fhirExtensionUrls.imagingStudyWorklist.seriesInstanceUID),
	},
	sopInstanceUID: {
		label: 'SOP Instance UID',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
		},
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource =>
			getExtensionValueString(resource, fhirExtensionUrls.imagingStudyWorklist.sopInstanceUID),
	},
	timeAtStatus: {
		label: 'Time At Status',
		options: { filter: false, sort: true },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => resource.timeAtStatus,
	},
	timeZone: {
		label: 'Time Zone',
		options: { filter: false, sort: true },
		filterType: 'multi-select',
		searchParameter: 'timeZone',
		searchValueSet: 'timeZone',
		getDisplay: resource => resource.timeZone,
	},
	transcriptionist: {
		label: 'Transcriptionist',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'transcriptionist',
		getDisplay: resource => resource.transcriptionist,
		cell: props => (
			<InlineEditCell
				size="small"
				userName={props.getValue()}
				internalStudyId={props.row.original.id}
				searchParams={{
					ispractitionerroleactive: true,
					managingorganization: props.row.original?.internalManagingOrganizationID,
					istranscriptionist: true,
				}}
				onUpdate={transcriptionistName => {
					props.table.options.meta?.updateData(props.row.index, 'transcriptionist', transcriptionistName);
				}}
				cellLabel="transcriptionist"
			/>
		),
	},
	transcriptionistFacility: {
		label: 'Transcriptionist Organization',
		options: { filter: false, sort: true },
		filterType: 'suggest',
		searchParameter: 'transcriptionistFacility',
		suggestScope: {
			resource: 'organization',
			label: 'name',
			extraParam: {
				organizationtype: 'PROV',
				istranscription: true,
			},
		},
		getDisplay: resource => resource.transcriptionistFacility,
	},
	specialArrangement: {
		label: 'Special Arrangement',
		options: { filter: false, sort: false },
		filterType: 'multi-select',
		searchParameter: 'specialArrangement',
		searchValueSet: 'specialArrangement',
		getDisplay: resource => resource.specialArrangement,
	},
	visitNumber: {
		label: 'Visit #',
		options: { filter: false, sort: true },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => resource.visitNumber,
	},
	orderNotes: {
		label: 'Order Notes',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'orderNotes',
		getDisplay: resource => resource.orderNotes,
	},
	clinicalNotes: {
		label: 'Clinical Notes',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'clinical',
		getDisplay: resource => getExtensionValueString(resource, fhirExtensionUrls.imagingStudy.notes.clinical),
	},
	history: {
		label: 'History',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'history',
		getDisplay: resource => resource.history,
	},
	laterality: {
		label: 'Laterality',
		options: { filter: false, sort: false },
		filterType: 'multi-select',
		searchParameter: 'laterality',
		searchValueSet: 'laterality',
		getDisplay: resource => getExtensionValueCodingArrayConcat(resource, fhirExtensionUrls.common.laterality),
	},
	bodyPart: {
		label: 'Body Part',
		options: { filter: false, sort: false },
		filterType: 'multi-select',
		searchParameter: 'bodyPart',
		searchValueSet: 'bodyPart',
		getDisplay: resource => getExtensionValueCodingArrayConcat(resource, fhirExtensionUrls.common.bodyPart),
	},
	modalityModifierArray: {
		label: 'Modality Modifier Array',
		options: { filter: false, sort: false },
		filterType: 'none',
		searchParameter: 'notes',
		getDisplay: resource => resource.modalityModifierArray,
	},
	anatomicFocus: {
		label: 'Anatomic Focus',
		options: { filter: false, sort: false },
		filterType: 'multi-select',
		searchParameter: 'anatomicFocus',
		searchValueSet: 'anatomicFocus',
		getDisplay: resource => getExtensionValueCodingArrayConcat(resource, fhirExtensionUrls.common.anatomicFocus),
	},
	priority: {
		label: 'Priority',
		options: { filter: false, sort: true },
		variableTypeToParseWhenSorting: 'number',
		filterType: 'multi-select',
		searchParameter: 'priority',
		searchValueSet: 'priority',
		getDisplay: resource => {
			const routineValue =
				getExtensionValueReference(resource, fhirExtensionUrls.studyPriority.customPriority)?.display ||
				getExtensionValueCoding(resource, fhirExtensionUrls.common.priority)?.display;
			return routineValue?.toUpperCase();
		},
		cell: props => (
			<InlineEditCell
				size="small"
				cellValue={props}
				searchValueSet="priority"
				onUpdate={(priority, proactEnableCustomStudyPriority, updatedPriority) => {
					const extensionUrl = fhirExtensionUrls.common.priority;
					if (proactEnableCustomStudyPriority) {
						const customPriorityIdx = props.row.original.extension.findIndex(
							ext => ext.url == fhirExtensionUrls.studyPriority.customPriority
						);
						if (customPriorityIdx === -1) {
							const customExtension = {
								url: fhirExtensionUrls.studyPriority.customPriority,
								valueReference: updatedPriority,
							};
							props.row.original.extension.push(customExtension);
						} else {
							props.table.options.meta?.updateData(
								props.row.index,
								`extension[${customPriorityIdx}]valueReference`,
								updatedPriority
							);
						}
					} else {
						props.table.options.meta?.updateData(props.row.index, 'priority', priority, extensionUrl);
					}
				}}
				cellLabel="priority"
				internalOrderId={props.row.original?.basedOn?.[0]?.id}
				imagingOrganizationId={props?.row?.original?.internalManagingOrganizationID}
			/>
		),
	},
	reasonForExam: {
		label: 'Reason For Exam',
		options: { filter: false, sort: false },
		filterType: 'multi-select',
		searchParameter: 'reasonForExam',
		searchValueSet: 'reasonForExam',
		getDisplay: resource => getExtensionValueCodingArrayConcat(resource, fhirExtensionUrls.common.reasonForExam),
	},
	view: {
		label: 'View',
		options: { filter: false, sort: true },
		filterType: 'multi-select',
		searchParameter: 'view',
		searchValueSet: 'view',
		getDisplay: resource => getExtensionValueCodingArrayConcat(resource, fhirExtensionUrls.common.view),
	},
	techniqueObject: {
		label: 'Technique Object',
		options: { filter: false, sort: true },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => resource.techniqueObject,
	},
	studyDateTime: {
		label: 'Study Date/Time',
		options: { filter: true, sort: true },
		variableTypeToParseWhenSorting: 'date',
		filterType: 'date-range',
		searchParameter: 'studydatetime',
		getDisplay: resource => {
			const value = resource.started;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
				}
			}
		},
		accessorFn: row => {
			const value = row.started;
			if (value != null) {
				const dateTime = new Date(value);
				if (isValidDateMapping(dateTime)) {
					return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
				}
			}
		},
		toolTip: true,
	},
	started: {
		label: 'Started',
		options: { filter: false, sort: true },
		filterType: 'none',
		searchParameter: '',
		getDisplay: resource => {
			const value = resource.started;
			if (value != null) {
				const dateTime = new Date(value);
				if (!isNaN(dateTime.getTime()) && dateTime.getYear() >= 0) {
					return resource.started;
				}
			}
		},
	},
	patientName: {
		label: 'Patient Name',
		options: {
			filter: true,
			sort: true,
			setCellProps: () => ({ style: { width: '0%' } }),
		},
		variableTypeToParseWhenSorting: 'text',
		filterType: 'text-search',
		queryType: 'multi-match',
		searchParameter: 'patientName',
		getDisplay: resource => `${getUserFullName(resource.subject?.display)}`,
		cell: props => <PatientProfileCell data={props.row.original} />,
	},
	patientgiven: {
		label: 'Patient First Name',
		options: {
			filter: true,
			sort: true,
			setCellProps: () => ({ style: { width: '0%' } }),
		},
		variableTypeToParseWhenSorting: 'text',
		filterType: 'text-search',
		searchParameter: 'patientgiven',
		getDisplay: resource => `${getWorklistParsedName(resource?.subject?.display)?.firstName}`,
		cell: props => getWorklistParsedName(props?.row?.original?.subject?.display)?.firstName,
	},
	patientfamily: {
		label: 'Patient Last Name',
		options: {
			filter: true,
			sort: true,
			setCellProps: () => ({ style: { width: '0%' } }),
		},
		variableTypeToParseWhenSorting: 'text',
		filterType: 'text-search',
		searchParameter: 'patientfamily',
		getDisplay: resource => `${getWorklistParsedName(resource?.subject?.display)?.lastName}`,
		cell: props => getWorklistParsedName(props?.row?.original?.subject?.display)?.lastName,
	},
	studyStatus: {
		label: 'Study Status',
		options: {
			filter: true,
			sort: true,
			customBodyRender: (value, tableMeta, updateValue) =>
				value?.props.text ? (
					<StudyStatusCellProvider
						studyId={value.props.text.studyId}
						status={value.props.text.status}
						onUpdate={(studyId, studyStatus) => {
							updateValue(
								<OverflowTooltip
									enableRecalc={false}
									text={{
										studyId,
										status: studyStatus,
									}}
								/>
							);
						}}
					/>
				) : null,
			setCellProps: () => ({ style: { width: '0%' } }),
		},
		filterType: 'multi-select',
		searchParameter: 'status',
		searchValueSet: 'status',
		getDisplay: resource => ({
			studyId: resource.id,
			status: getExtensionValueCoding(resource, fhirExtensionUrls.common.studyStatus).display,
		}),
		accessorFn: row => getExtensionValueCoding(row, fhirExtensionUrls.common.studyStatus)?.display,
		cell: props => (
			<StudyStatusCellProvider
				studyId={props.row.original.id}
				status={props.getValue()}
				orgId={props.row.original?.imagingOrganizationId || props.row.original.internalManagingOrganizationID}
				onUpdate={value => {
					const studyStatusIdx = props.row.original.extension?.findIndex(
						ext => ext.url == fhirExtensionUrls.common.studyStatus
					);
					props.table.options.meta?.updateData(
						props.row.index,
						`extension[${studyStatusIdx}]valueCoding.display`,
						value
					);
				}}
				rejectionReasonArr={getExtensionValueCodingArray(
					props.row.original,
					fhirExtensionUrls.imagingStudy.rejectionReason
				)}
			/>
		),
	},
	studyDescription: {
		label: 'Study Description',
		options: { filter: true, sort: false },
		filterType: 'text-search',
		searchParameter: 'description',
		getDisplay: resource => resource.description,
	},
	requestedProcedureID: {
		label: 'Requested Procedure ID',
		options: { filter: true, sort: true },
		filterType: 'text-search',
		searchParameter: 'requestedProcedureID',
		getDisplay: resource => getExtensionValueString(resource, fhirExtensionUrls.imagingStudy.requestedProcedureID),
	},
	workflowStep: {
		label: 'Workflow Step',
		options: { filter: true, sort: true },
		filterType: 'multi-select',
		searchParameter: 'workflowStep',
		searchValueSet: 'workflowStatus',
		valueSetExtraParam: { operator: '=' },
		getDisplay: resource => getExtensionValueString(resource, fhirExtensionUrls.organization.workflowStep),
	},
	flagCol: {
		label: ' ',
		customHeader: props => <CheckboxSelectAll rows={props.rows} />,
		getDisplay: resource => {
			const dicomUid = resource.identifier
				?.find(id => id.system === 'urn:dicom:uid')
				?.value?.split('urn:oid:')?.[1];

			return JSON.stringify({
				id: resource.id,
				isCritical: !!resource.extension?.find(elem => elem.url === fhirExtensionUrls.imagingStudy.isCritical)
					?.valueBoolean,
				dicomUid,
				internalManagingOrganizationId: resource.internalManagingOrganizationID,
				referringPhysicianId: resource.referringPhysicianId,
				accessionNumber: resource.identifier?.find(elem =>
					elem.type?.coding?.some(item => item.code === 'ACSN')
				)?.value,
			});
		},
		options: {
			customBodyRender: value => <CheckBoxOrCritical text={value.props.text} />,
			setCellProps: () => ({ style: { width: '0%' } }),
			viewColumns: false,
			draggable: false,
			excludeFromSave: true,
		},
		cell: props => (
			<CheckboxCell
				data={{
					id: props.row.original.id,
					isCritical: !!props.row.original.extension?.find(
						elem => elem.url === fhirExtensionUrls.imagingStudy.isCritical
					)?.valueBoolean,
					dicomUid: props.row.original.identifier
						?.find(id => id.system === 'urn:dicom:uid')
						?.value?.split('urn:oid:')?.[1],
					internalManagingOrganizationId: props.row.original.internalManagingOrganizationID,
					referringPhysician: props.row.original.referringPhysician,
					referringPhysicianId: props.row.original.referringPhysicianId,
					accessionNumber: props.row.original.identifier?.find(elem =>
						elem.type?.coding?.some(item => item.code === 'ACSN')
					)?.value,
					basedOn: props.row.original.basedOn,
					patientID: props.row.original.patientID,
					patientEmail: props.row.original.patientEmail,
					subject: props.row.original.subject,
					rawData: props.row.original.rawData,
				}}
			/>
		),
	},
	flagColTF: {
		label: ' ',
		customHeader: props => <CheckboxSelectAll rows={props.rows} />,
		getDisplay: resource => {
			const dicomUid = resource.identifier
				?.find(id => id.system === 'urn:dicom:uid')
				?.value?.split('urn:oid:')?.[1];

			return JSON.stringify({
				id: resource.id,
				isCritical: !!resource.extension?.find(elem => elem.url === fhirExtensionUrls.imagingStudy.isCritical)
					?.valueBoolean,
				dicomUid,
				internalManagingOrganizationId: resource.internalManagingOrganizationID,
				referringPhysicianId: resource.referringPhysicianId,
				accessionNumber: resource.identifier?.find(elem =>
					elem.type?.coding?.some(item => item.code === 'ACSN')
				)?.value,
			});
		},
		options: {
			customBodyRender: value => <CheckBoxOrCritical text={value.props.text} />,
			setCellProps: () => ({ style: { width: '0%' } }),
			viewColumns: false,
			draggable: false,
			excludeFromSave: true,
		},
		cell: props => (
			<CheckboxCell
				data={{
					id: props.row.original.id,
					isCritical: !!props.row.original.extension?.find(
						elem => elem.url === fhirExtensionUrls.imagingStudy.isCritical
					)?.valueBoolean,
					dicomUid: props.row.original.identifier
						?.find(id => id.system === 'urn:dicom:uid')
						?.value?.split('urn:oid:')?.[1],
					internalManagingOrganizationId: props.row.original.internalManagingOrganizationID,
					referringPhysician: props.row.original.referringPhysician,
					referringPhysicianId: props.row.original.referringPhysicianId,
					accessionNumber: props.row.original.identifier?.find(elem =>
						elem.type?.coding?.some(item => item.code === 'ACSN')
					)?.value,
					basedOn: props.row.original.basedOn,
					patientID: props.row.original.patientID,
					patientEmail: props.row.original.patientEmail,
					subject: props.row.original.subject,
					rawData: props.row.original.rawData,
				}}
			/>
		),
	},
	spacingCol: {
		label: ' ',
		getDisplay: () => '',
		options: {
			viewColumns: false,
			draggable: false,
			excludeFromSave: true,
		},
	},
	consultingPhysicianText: {
		label: 'Consulting Physician',
		options: { filter: true, sort: true },
		variableTypeToParseWhenSorting: 'text',
		filterType: 'text-search',
		searchParameter: 'consultingPhysician',
		getDisplay: resource =>
			resource.consultingPhysician?.map(physician => getUserFullName(physician, true)).join(' | '),
	},
};

export default ImagingStudyWorklistMapping;

function CheckBoxOrCritical({ text }) {
	const { selectedStudies, toggleStudyPresence } = useContext(SelectionContext);

	const json = JSON.parse(text);
	const { isCritical } = json;

	const isSelected = selectedStudies.map(s => s.id).includes(json.id);

	return (
		<Box
			sx={{
				height: '20px',
				width: '20px',

				'&:hover': {
					'.checkbox': { display: 'flex' },
					'.flag': { display: 'none' },
				},
			}}
		>
			<RowCheckbox id={json.id} data={json} isSelected={isSelected} toggleStudyPresence={toggleStudyPresence} />

			<Box className="flag">
				{isCritical && !isSelected ? (
					<FlagOutlinedIcon
						fontSize="small"
						sx={{
							color: '#CF6679',
							border: '#CF6679',
							borderRadius: '6px',
							backgroundColor: 'rgba(207, 102, 121, 0.1)',
							width: '20px',
							height: '20px',
						}}
					/>
				) : null}
			</Box>
		</Box>
	);
}

function RowCheckbox({ id, data, isSelected, toggleStudyPresence }) {
	return (
		<Box
			className="checkbox"
			sx={{
				display: isSelected ? 'flex' : 'none',
			}}
		>
			<Checkbox
				checked={isSelected}
				checkedIcon={
					<Box
						sx={{
							height: '20px',
							width: '20px',
							borderRadius: '6px',
							background: '#42A5F51A',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<CheckIcon
							sx={{
								color: 'rsPrimary.main',
								width: '14px',
								height: '14px',
							}}
						/>
					</Box>
				}
				icon={
					<Box
						sx={{
							height: '18px',
							width: '18px',
							borderRadius: '6px',
							border: '1px solid rgba(255,255,255,.2)',
						}}
					/>
				}
				sx={{
					padding: '0 !important',
				}}
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
					toggleStudyPresence({
						id,
						data,
					});
				}}
			/>
		</Box>
	);
}
